@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

body {
    font-family: 'Rubik', sans-serif;
}

.nunito {
    font-family: 'Nunito', sans-serif !important;
}

.page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .nav-menu>li .nav-submenu {
    display: block !important;
}

.page-wrapper.horizontal-wrapper .page-body-wrapper.horizontal-menu header.main-nav .main-navbar .nav-menu>li .nav-submenu li:hover .nav-sub-childmenu {
    display: block !important;
}

.page-wrapper.horizontal-wrapper .according-menu.other {
    display: none !important;
}

.font-bold {
    font-weight: bold;
}

.dropdown-menu.dropdown-menu-right .with-arrow {
    right: 0;
}

.dropdown-menu .with-arrow {
    position: absolute;
    height: 10px;
    overflow: hidden;
    width: 40px;
    top: -10px;
}

.primary-color {
    background: #29C76E;
}

.dropdown-menu.dropdown-menu-right .with-arrow>span {
    right: 20px;
    left: auto;
}

.dropdown-menu .with-arrow>span {
    transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    width: 15px;
    height: 15px;
    top: 3px;
    left: 15px;
    position: absolute;
    content: "";
    border-radius: 6px 0 0;
}

.required::after {
    content: '*';
    color: red;
    padding-left: 5px;
    font-size: 1.2em;
}


/* TOASTR CSS */

.toast {
    border: 0px !important;
}

#toast-container>div {
    width: 400px !important;
    opacity: 1 !important;
}

#toast-container>.toast:before {
    position: fixed;
    font-family: "Nunito", sans-serif;
    font-size: 24px;
    line-height: 18px;
    float: left;
    color: #FFF;
    padding-right: 0.5em;
    margin: auto 0.5em auto -1.5em;
}

.toast-success {
    background-color: #17A817 !important;
}

.toast-error {
    background-color: #D50A0A !important;
}

.toast-info {
    background-color: #5499C7 !important;
}

.prec {
    top: 25px;
    position: relative;
    font-size: 25px;
}


/* END TOASTR CSS */

.logo {
    display: block;
    width: auto !important;
    height: 30px !important;
}

.odss__shd {
    background: #FAF5DE;
}

#sidebar_min {
    background-color: #13B255;
    z-index: 998;
    width: 60px;
    /* box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1); */
    position: fixed !important;
    display: -webkit-box;
    display: block;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    left: 0;
    bottom: 0;
    top: 0;
}

#sidebar_min .icon-linky {
    text-decoration: none;
    background: inherit;
    height: 44px;
}

#sidebar_min .icon-disp {
    margin: 0;
    top: 0;
    left: 0;
    align-items: center;
    display: grid;
    justify-items: center;
}

.ticket-rotate {
    transform: rotate(-45deg);
}

#sidebar_min .active,
#sidebar_min .active:hover {
    height: 40px;
    width: 100%;
    background: #343A40 !important;
    color: #FFF !important;
    border-radius: 0.5rem;
    box-shadow: 0px 5px 10px -1px rgba(0, 0, 0, 0.15);
}

.sttng_mn {
    width: 250px;
    background: #E5E8E8 !important;
}

.st-icon {
    width: 45px;
    height: 45px;
    text-align: center;
}

.sttng_mn_ic {
    width: 45px;
    height: 45px;
}

.setting-item:hover {
    background: #E5E8E8;
    border-radius: 5px;
}

.setting-item.active,
.setting-item.active:hover {
    background: #343A40;
    color: #FFF !important;
    border-radius: 5px;
    box-shadow: 0px 5px 10px -1px rgba(0, 0, 0, 0.15);
}

.ml-250 {
    margin-left: 250px !important
}

.badge-purple {
    color: #FFF;
    background-color: #8e44ad;
    font-weight: normal;
}

.image-center {
    display: block;
    text-align: center;
    margin: auto;
}

.sttng__bgi {
    background-repeat: no-repeat;
    /* background-position: center; */
    background-size: cover;
    height: 100px;
}

.sq-50 {
    width: 50px;
    height: 50px
}

.sq-40 {
    width: 40px;
    height: 40px
}

.sq-30 {
    width: 30px;
    height: 30px
}

.sttng_strp {
    background-image: url('../images/topographic_contour_lines_map.svg');
    background-repeat: repeat;
    background-position: center;
    background-size: cover;
}

.block_strp {
    background-image: url('../images/a13f7362f05da71627491eb19944a53e.png');
    background-repeat: repeat;
    background-position: center;
    background-size: cover;
}

.id-00 {
    background-image: url('../images/0e4537779b99b50a40a4c70b5acdf857.svg');
    background-repeat: repeat;
    background-position: center;
    background-size: cover;
}

.wrapper {
    /* -ms-flex-align: center; */
    /* align-items: center; */
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    min-height: 100vh;
}

.wrapper-background {
    /* background-image: url('../images/0e4537779b99b50a40a4c70b5acdf857.svg'); */
    /* background-color: #d6dbdf; */
    /* background-image: linear-gradient(135deg, #d6dbdf 0%, #ffffff 51%); */
    /* background: linear-gradient(135deg, #FFF 10%, #fef3c7 100%); */
}

.main-bg {
    background-image: url('../images/main-bg-01.svg');
    background-repeat: repeat;
    background-position: center;
    background-size: cover;
}

.main-ht-bg {
    background-image: url('../images/Cs1nccZYEwsvUoOOQ.svg');
    background-repeat: repeat;
    background-position: center;
    background-size: cover;
}

.gx-container {
    margin: 0 auto;
    z-index: 1;
}

.gx-900 {
    width: 900px !important;
}

.hx-container {
    width: 760px !important;
    margin: 0 auto;
    padding: 32px 0;
    z-index: 1;
}

.landing-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: flex-end;
    -ms-flex-pack: justify;
    justify-content: space-between;
    overflow: hidden;
}

.odyssey {
    font-size: 2.25rem;
    font-weight: 700;
    line-height: 1.2;
}

.text-theme,
.text-success {
    color: #13B255 !important;
}

.pui-pill {
    font-size: 14px;
    display: inline-block;
    text-decoration: none;
}

.form-group {
    margin-bottom: 1rem;
}

.btn-success {
    background-color: #13B255;
    border-color: #13B255;
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active {
    background-color: #0B9946 !important;
    border-color: #0B9946 !important;
}

.aside-link-title,
.lttr-spc {
    letter-spacing: 0.7px !important;
}

.kiOAkj {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    margin: 0px auto;
    /* max-width: 960px; */
    /* padding: 0px 20px; */
    position: relative;
}

.text-sm {
    font-size: 13px;
    line-height: 1.7;
    letter-spacing: .7px;
}

.sq-10 {
    width: 10px;
    height: 10px;
}

._spco {
    margin-top: 65px;
    margin-left: 290px;
}

.breadcrumb-item::before {
    float: left;
    padding-right: .5rem;
    color: #6c757d;
    content: var(--bs-breadcrumb-divider, "/");
}

.breadcrumb-item::before {
    content: "/";
}

.swal-title:not(:last-child) {
    margin-bottom: 5px;
}

.swal-title:first-child {
    margin-top: 13px;
}

.swal-title {
    color: rgba(0, 0, 0, .65);
    font-weight: normal;
    font-family: 'Rubik', sans-serif;
    text-transform: none;
    position: relative;
    display: block;
    padding: 13px 16px;
    font-size: 20px;
    line-height: normal;
    text-align: center;
    margin-bottom: 0;
}

.swal-text {
    font-size: 14px;
    font-family: 'Rubik', sans-serif;
    position: relative;
    float: none;
    line-height: inherit;
    vertical-align: top;
    text-align: center;
    display: inline-block;
    margin: 0;
    padding: 0 10px;
    font-weight: 400;
    color: rgba(0, 0, 0, .64);
    max-width: calc(100% - 20px);
    overflow-wrap: break-word;
    box-sizing: border-box;
}

.swal-button {
    padding: 6px 18px;
}

.swal-modal {
    width: 320px;
    opacity: 0;
    pointer-events: none;
    background-color: #fff;
    text-align: center;
    border-radius: 5px;
    position: static;
    margin: 20px auto;
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    z-index: 10001;
    transition: opacity .2s, -webkit-transform .3s;
    transition: transform .3s, opacity .2s;
    transition: transform .3s, opacity .2s, -webkit-transform .3s;
}

.swal-button--danger {
    background-color: #E64942 !important;
}

.swal-footer {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}

.swal-footer {
    text-align: right;
    margin-top: 13px;
    padding: 8px;
    border-radius: inherit;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.swal-button--cancel {
    background-color: #E1E0E0;
    border: none;
    box-shadow: none;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
    padding: 8px 24px;
    margin: 0;
    cursor: pointer;
}

.py-1-5 {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
}

.Toastify__toast-body {
    font-family: 'Rubik', sans-serif;
    font-size: 13px;
    line-height: 1.7;
    letter-spacing: .7px;
}


.steps {
    display: inline-grid;
    grid-auto-flow: column;
    overflow: hidden;
    overflow-x: hidden;
    overflow-x: auto;
    counter-reset: step;
    grid-auto-columns: 1fr;
}

.steps .step {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-template-columns: auto;
    grid-template-rows: repeat(2, minmax(0, 1fr));
    grid-template-rows: 40px 1fr;
    place-items: center;
    text-align: center;
    min-width: 4rem;
}

.steps .step::after {
    /* content: ""; */
    counter-increment: step;
    z-index: 1;
    position: relative;
    grid-column-start: 1;
    grid-row-start: 1;
    display: grid;
    height: 2rem;
    width: 2rem;
    place-items: center;
    place-self: center;
    border-radius: 9999px;
    --tw-bg-opacity: 1;
    background-color: antiquewhite;
    --tw-text-opacity: 1;
    color: hsl(var(--bc)/var(--tw-text-opacity));
}

.steps .step-primary+.step-primary::before,
.steps .step-primary::after {
    --tw-bg-opacity: 1;
    background-color: antiquewhite;
    --tw-text-opacity: 1;
    color: hsl(var(--pc)/var(--tw-text-opacity));

}

.steps .step::before {
    top: 0;
    grid-column-start: 1;
    grid-row-start: 1;
    height: .12rem;
    width: 100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    --tw-bg-opacity: 1;
    background-color: lightgray;
    --tw-text-opacity: 1;
    color: hsl(var(--bc)/var(--tw-text-opacity));
    content: "";
}

.p-1-5 {
    padding: 0.375rem;
}

.all-white {
    background: #FFF !important;
}

.-z-1 {
    z-index: -1;
}

.origin-0 {
    transform-origin: 0%;
}

input:focus~label,
input:not(:placeholder-shown)~label,
textarea:focus~label,
textarea:not(:placeholder-shown)~label,
select:focus~label,
select:not([value='']):valid~label {
    /* @apply transform; scale-75; -translate-y-6; */
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    /* transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)); */
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
    --tw-translate-y: -1.5rem;
    z-index: 5;
    top: 0.725rem;
}

input:focus~label,
select:focus~label {
    /* @apply text-black; left-0; */
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
    left: 0px;
    z-index: 5;
    top: 0.725rem;
}

.scrollbar-thin::-webkit-scrollbar {
    width: 5px;
    /* Set the width of the scrollbar */
}

.scrollbar-thin::-webkit-scrollbar-thumb {
    background-color: darkgray;
    /* Set the color of the scrollbar thumb */
    border-radius: 5px;
    margin: 15px 0px;
    /* Set the border radius of the scrollbar thumb */
}

.blurred {
    filter: blur(5px);
    /* Adjust the blur intensity as needed */
}

.slide-1-image {
    background-image: url('../images/freepik__upload__70693.jpeg');
}

.slide-2-image {
    background-image: url('../images/freepik__upload__93318.jpeg');
}

.slide-3-image {
    background-image: url('../images/freepik__upload__71207.jpeg');
}

.get-started {
    background-image: url('../images/freepik__upload__64277.jpeg');
}

.orange-man {
    background-image: url('../images/auth-side-image.jpg');
}

.trumpet-woman {
    background-image: url('../images/bigfan-sign-up-image.jpg');
}

.potrait {
    background-image: url('../images/portrait-ethnic.png');
}

.slide-layout {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}